import { z } from 'zod';

import { RouterOutput } from '../trpc/router';

export const phases = ['Menstrual', 'Follicular', 'Ovulatory', 'Early Luteal', 'Late Luteal', 'Luteal'] as const;
export const phasesSchema = z.enum(phases);
export type PhaseName = z.infer<typeof phasesSchema>;

export const intensities = ['LOW', 'MODERATE', 'HIGH'] as const;
export const intensitiesSchema = z.enum(intensities);
export type Intensity = z.infer<typeof intensitiesSchema>;

export const targetAreas = ['FULL_BODY', 'LEGS', 'BUTT', 'CORE', 'ARMS', 'STRETCH'] as const;
export const targetAreasSchema = z.enum(targetAreas);
export type TargetArea = z.infer<typeof targetAreasSchema>;

export const PreferredGoals = {
  'build-muscle': 'Build muscle',
  'lose-weight': 'Lose weight',
  'maintain-physique': 'Mantain physique',
  'trying-to-get-pregnant': 'Trying to get pregnant',
  'reduce-pains-related-to-my-period': 'Reduce pains related to my period',
  'increase-midfulness': 'Increase mindfulness',
  'build-healthier-eating-habits': 'Build healthier eating habits',
} as const;

export type PreferredGoals = typeof PreferredGoals[keyof typeof PreferredGoals];

export type FormValues = {
  menstrualPain: string;
  birthControl: string;
  menstrualCycleConsistent: string;
  symptoms: string[];
  healthDisorders: string;
  fitnessGoal: string;
  nutritionGoals: string[];
};

export interface ReferralProfile {
  id: string;
  referralCount: number;
  shareUrl: string;

  // firstName: string;
  // lastName: string;
  // inviteCount: number;
  // monthlyReferralCount: number;
  // prevMonthlyReferralCount: number;
  // rank: number;
  // monthlyRank: number;
  // prevMonthlyRank: number;
  // rewards: RewardsEntity[];
  // email: string;
  // vanityKeys: string[];
  // createdAt: number;
  // referralSource: string;
  // fraudRiskLevel?: string;
  // fraudReasonCode?: string;
  // isWinner: boolean;
  // shareCount: ShareCount;
  // impressionCount: number;
  // uniqueImpressionCount: number;
  // referrals: string[];
  // monthlyReferrals: string[];
  // metadata?: Metadata;
  // referrer: string | null;
}
export interface RewardsEntity {
  id: string;
  rewardId: string;
  status: string;
  unread: boolean;
  isReferrer: boolean;
  isFulfilled: boolean;
  isAvailable: boolean;
  approved: boolean;
  approvedAt: number;
  fulfilledAt?: string;
  referredId: string;
  referrerId: string;
}
export interface ShareCount {
  email: number;
  facebook: number;
  twitter: number;
  linkedin: number;
  pinterest: number;
  sms: number;
  messenger: number;
  whatsapp: number;
  wechat: number;
}
export interface Metadata {
  stripeCouponCode: string;
  percentOff: number;
}

export type WorkoutSlim = Omit<RouterOutput['workouts']['byId']['data'], 'previewVideo' | 'exerciseGear'>;
